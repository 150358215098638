import React, { memo, useEffect } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { IoIosClose } from 'react-icons/io';
import { getParameterByName, loaded } from './../../utils/helpers';
import loadable from '@loadable/component';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import debounce from 'lodash/debounce';

const SearchIcon = loadable(() => import('../../images/svg/SearchIcon'));

const SearchBox = ({ searching, toggleSearch, refine }) => {
  const isMobile = loaded && window.innerWidth < 992;
  let input;
  const [, setSearch] = useQueryParams({
    q: withDefault(StringParam, ''),
  });

  useEffect(() => {
    const s = getParameterByName('q');
    if (input && s && s !== '') {
      input.value = s;
      refine(s);
    }
  }, [input]);

  useEffect(() => {
    if (searching && input && !isMobile) {
      input?.focus();
    }
  }, [searching, input]);

  return (
    <div className={`header__search-box ${searching ? 'open' : ''}`}>
      <form
        action=""
        method="GET"
        onSubmit={() => {
          event.preventDefault();
          input.blur();
        }}
      >
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />

        <button type="button" className="btn-close-search-box" onClick={() => toggleSearch()}>
          <IoIosClose />
        </button>
        <span className="icon-search d-lg-none">
          <SearchIcon width={20} height={20} />
        </span>
        <input
          ref={(node) => (input = node)}
          type="search"
          placeholder="Search product, brands, concerns..."
          defaultValue=""
          onChange={debounce((e) => {
            refine(e.target.value);
            setSearch({ q: e.target.value });
          }, 300)}
        />
      </form>
    </div>
  );
};

export default connectSearchBox(memo(SearchBox));
